(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

window.$('.accept-cookies').click(function (ev) {
  ev.preventDefault();

  window.$.get(this.href).success(function () {
    window.$('.cookie-law').remove();
  });
});

window.$('.fare-minus').click(function () {
  var input = window.$('#fares_' + window.$(this).data('fare'));
  var value = parseInt(input.val() === '' ? 0 : input.val(), 10);

  if (value > 0) {
    input.val(value - 1);
  }

  updateTotal();
});

window.$('.fare-plus').click(function () {
  var input = window.$('#fares_' + window.$(this).data('fare'));
  var value = parseInt(input.val() === '' ? 0 : input.val(), 10);

  input.val(value + 1);

  updateTotal();
});

window.$('#cart-popover').popover({
  html: true,
  container: 'body',
  content: function content() {
    return window.$('#popover_content_wrapper').html();
  }
});

window.$('[data-toggle="tooltip"]').tooltip();

$('ul.nav li.dropdown').hover(function () {
  $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeIn(250);
}, function () {
  $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeOut(250);
});

},{}]},{},[1]);
